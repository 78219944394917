.order-table {
    // td {
    //     font-size: 12px;
    // }

    .order-img-container {
        position: relative;
        background-color: white;
        box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.062);
        width: 36px;
        height: 36px;
        border-radius: 9px;
        @include flexb;

        .order-img {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .order-zoom-in {
            @include flexb;
            position: absolute;
            visibility: hidden;
            background-color: transparent;
            border-radius: 9px;
            height: 100%;
            width: 100%;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            img {
                height: 50%;
                width: 50%;
                opacity: 0;
                transition: all 0.3s ease-in-out;
            }
        }

        &:hover {
            // .order-img {
            //     opacity: 0.5;
            // }

            .order-zoom-in {
                visibility: visible;
                background-color: rgba(0, 0, 0, 0.37);

                img {
                    opacity: 1;
                }
            }
        }
    }

    .btn-detail {
        @include flexb;
        gap: 10px;
        background-color: transparent;
        border: 1px solid #00acd2;
        border-radius: 8px;
        height: 45px;
        color: #00acd2;
        font-size: 12px;
        font-weight: 500;
        padding: 5px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: rgba(0, 0, 0, 0.055);
        }
    }

    .order-important {
        color: #f05a29;
        font-size: 18px;
        font-weight: 500;
    }
}
