.products-container {
    .products-header {
        @include flexb(row, space-between);
        min-height: 70px;

        a {
            text-decoration: none;
        }
    }
}

.product-detail-container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 3% 0;

    .product-detail-header {
        @include flexb(row, space-between);
        min-height: 70px;
    }

    .product-detail-body {
        @include flexb(row, center, flex-start);
        gap: 20px;

        .product-detail-cards {
            width: 100%;

            .sob-card {
                margin: 0;
                padding: 3%;

                .sob-card-item-title {
                    span {
                        font-weight: 400;
                    }
                }
            }
        }

        .sob-audit-card {
            margin: 0;
            max-width: 400px;
        }
    }
}
