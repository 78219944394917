.dashbord-container {
    .dashbord-header {
        @include flexb(row, space-between);
        min-height: 70px;
    }

    .dashbord-body {
        @include flexb(row, center, flex-start);
        gap: 20px;

        .dashboard-leftSide {
            flex-grow: 1;
            flex-basis: 25%;
        }

        .dashb-actions {
            background-color: white;
            padding: 1rem;
            border-radius: 1rem;

            .dashb-actions-header {
                @include flexb(row, flex-start);
                gap: 10px;
                margin-bottom: 20px;
            }

            .dashb-actions-body {
                margin-top: 20px;

                .sob-btn-custom {
                    margin: 10px 0;
                }
            }
        }

        .Card_container {
            min-height: 300px;
        }

        .dashboard-posts {
            @include flexb(column);
            flex-grow: 1;
            flex-basis: 50%;

            & > div {
                width: 100%;
            }

            .Card_container {
                flex-shrink: 1;
                margin-bottom: 20px;
                width: 100%;

                .card_content_image_container > img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .dashb-statistics {
            background-color: white;
            padding: 1rem;
            border-radius: 1rem;
            flex-grow: 1;
            flex-basis: 25%;
            min-width: 320px;

            .dashb-stats-header {
                @include flexb(row, flex-start);
                gap: 10px;
                margin-bottom: 20px;
            }

            .StatisticalCard__container {
                margin: 0;
                margin-bottom: 20px;
            }

            .sob-v2-accordionCard_big_container {
                margin-bottom: 20px;
                min-width: 0;
                width: 100%;
                // max-width: 320px;
                border: 1px solid #eaeaea;
                box-shadow: 0 0 15px rgba(194, 194, 194, 0.11);
                // width: 100%;

                .sob-v2-accordionCard_container_content {
                    // height: auto !important;
                    overflow-y: auto;
                    scrollbar-width: thin;
                    flex-direction: column;

                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                    }

                    &::-webkit-scrollbar-track-piece {
                        background: rgba(194, 194, 194, 0.205);
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #dddddd;
                        border-radius: 5px;
                    }
                }

                .sob-v2-accordionCard_container {
                    height: 80px;
                }

                .event-show-more {
                    @include flexb;
                    font-size: 13px;
                    color: #00acd2;
                    cursor: pointer;
                }

                .sob-v2-accordionCard_container_title {
                    font-size: 17px;
                    font-weight: 600;
                }
            }
        }
    }
}
