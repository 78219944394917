.failed-container {
    @include flexb;
    flex-direction: column;
    height: calc(100vh - 50px);
    width: 100%;

    h1 {
        font-weight: 700;
        font-size: 18px;
    }

    div {
        font-size: 14px;
        margin: 10px 0;
    }
}
