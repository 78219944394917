.labs-colors {
    @include flexb(row, flex-start);
    gap: 15px;

    span {
        min-width: 36px;
        min-height: 36px;
        background-color: #02b1d6;
        border-radius: 50%;
        @include flexb;
        cursor: pointer;
        position: relative;

        img {
            display: none;
        }

        &:hover {
            transform: scale(1.15);
        }
    }

    .labs-colors-active {
        transform: scale(1.15);

        img {
            display: block;
        }
    }
}
