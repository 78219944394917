:root {
    --sob-primary-50: #fef2f2 !important;
    --sob-primary-100: #fce8e7 !important;
    --sob-primary-200: #f9d2d3 !important;
    --sob-primary-300: #f4adaf !important;
    --sob-primary-400: #ec8084 !important;
    --sob-primary-500: #df4751 !important;
    --sob-primary-600: #cc3244 !important;
    --sob-primary-700: #ac2437 !important;
    --sob-primary-800: #902134 !important;
    --sob-primary-900: #7c1f32 !important;
    --sob-primary-950: #450c16 !important;
    // --sob-v2-color-palette-primary-header: hsl(174, 67%, 43%) !important;
    // --sob-v2-color-header: #ffffff !important;
    // --sob-v2-color-logo: #ffffff !important;
    --sob-v2-color-palette-primary: var(--sob-primary-600) !important;
    --sob-v2-color-palette-primary-hover: var(--sob-primary-700) !important;
    // secondary
    --sob-v2-color-palette-secondary: var(--sob-primary-100) !important;
    --sob-v2-color-palette-secondary-hover: var(--sob-primary-200) !important;

    $sob-v2-color-palette-primary: var(--sob-primary-600) !important;
    $sob-v2-color-palette-primary-hover: var(--sob-primary-700) !important;
    // secondary
    $sob-v2-color-palette-secondary: var(--sob-primary-100) !important;
    $sob-v2-color-palette-secondary-hover: var(--sob-primary-200) !important;
}

html {
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
    }

    &::-webkit-scrollbar-track-piece {
        background: rgba(194, 194, 194, 0.205);
    }
    &::-webkit-scrollbar-thumb {
        background: #ffa2a2;
        border-radius: 5px;
    }

    min-width: 1100px;
}

.fade {
    animation: fade 1s ease-out;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ReactModal__Overlay {
    z-index: 9999;
}

.sob-btn {
    height: 45px !important;
}

.sob-table-pagination {
    .sob-btn {
        height: 45px !important;
        padding: 10px 10px !important;
        border-radius: 10px !important;
    }
}

.Calendar__TooltipEvent-footer {
    display: none !important;
}

.TablePage__btn,
.TablePage {
    background-color: #f4fcfd;
    color: #02829f;
    font-weight: 600;
    font-size: 16px;
}

.TablePage__btn:hover,
.TablePage:hover {
    background-color: #ddfbff;
    color: #02829f;
}
