.labs-btn {
    background-color: #1bb9ac0e;
    color: #02829f;
    // height: 45px;
    font-weight: 500;
    @include flexb(row);
    gap: 10px;
    margin: 0;
    padding: 15px 25px;

    &:hover {
        background-color: #1491861a;
        color: #02829f;
    }

    &:focus {
        background-color: #1bb9ac0e;
        color: #02829f;
        // color: #dd4151;
    }
}

// .btn {
//     padding: 0;
// }

.btn-special {
    background-color: #1bb9ac;
    color: white;
    height: 50px;
    font-weight: 500;
    @include flexb(row, space-between);
    gap: 10px;
    margin: 0;

    &:hover {
        background-color: #18998e;
    }

    &:focus {
        background-color: #18998e;
    }
}

.btn-height {
    height: 41px;
}

.btn-outline {
    background-color: white;
    color: #1bb9ac;
    border: 2px solid #1bb9ac28;
    height: 62px;
    font-weight: 500;
    @include flexb(row, space-between);
    gap: 10px;
    margin: 0;

    &:hover {
        background-color: #1491861a;
        color: #1bb9ac;
    }

    &:focus {
        background-color: #1bb9ac0e;
        color: #1bb9ac;
    }
}

.btn-plus {
    background-color: transparent;
    border: none;
    cursor: pointer;
    @include flexb;
    gap: 10px;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    font-weight: 400;

    &[label='yellow'] {
        color: #e0a43a;

        path {
            fill: #e0a43a;
        }
    }

    &[label='green'] {
        color: #03bf9c;

        path {
            fill: #03bf9c;
        }
    }

    &[label='blue'] {
        color: #45bfea;

        path {
            fill: #45bfea;
        }
    }

    &[label='orange'] {
        color: #f86d00;

        path {
            fill: #f86d00;
        }
    }

    &:hover {
        filter: brightness(0.8);
    }
}

.order-btn {
    background-color: #00aca1;
    color: white;
    @include flexb(row, space-between);
    border-radius: 8px;
    border: none;
    height: 45px;
    width: 150px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #008f85;
    }
}

.orderBack-btn {
    background-color: transparent;
    color: #00aca1;
    @include flexb(row, space-between);
    gap: 20px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    // height: 45px;
    // width: 100px;
    // padding: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #008f85;
    }
}
