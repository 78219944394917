.demande-feedback-container {
    // padding: 20px;

    .comment-container {
        @include flexb(row, flex-start, flex-start);
        gap: 14px;
        border-top: 1px solid #ebebeb;
        padding: 20px 0;

        .comment-pic {
            flex-shrink: 0;
            border-radius: 16px;
            height: 63px;
            width: 63px;
            background-color: #e5f4f7;
        }

        .comment-body {
            h5 {
                font-size: 1rem;
                margin: 5px 0 2px;
            }

            .comment-date {
                font-weight: 300;
                font-size: 15px;
                color: #898989;
            }

            .comment-content {
                margin: 10px 0;
                font-weight: 500;
                font-size: 15px;

                i {
                    color: #18b1d4;
                    font-style: normal;
                }
            }
        }
    }

    .comment-container2 {
        background-color: #edeff2;
        padding: 10px 12px;
        margin-top: 20px;
        border-radius: 8px;
        // @include flexb(row, flex-start, flex-start);

        .comment-head {
            @include flexb(row, flex-start, center);

            .comment-head-info {
                margin-left: 10px;
                display: block;
                line-height: 15px;

                h5 {
                    font-size: 0.75rem;
                    font-weight: 600;
                    margin: 0;
                }

                .comment-date {
                    @include flexb(row, flex-start);
                    gap: 5px;
                    font-weight: 300;
                    font-size: 12px;
                    color: #898989;
                }
            }
        }

        .comment-body {
            .comment-content {
                margin: 10px 0 0;
                font-size: 13px;
                color: #627282;

                i {
                    color: #18b1d4;
                    font-style: normal;
                }
            }
        }
    }

    .demande-textArea-container {
        background-color: #fafafa;
        border-radius: 13px;
        padding: 20px 10px;

        .demande-textArea {
            @include flexb(row, flex-start, flex-start);
            gap: 14px;

            textarea {
                padding: 10px;
                border: 1px solid #f0f0f0;
                border-radius: 8px;
                resize: none;
                width: 100%;
                height: 200px;
                font-size: 14px !important;

                &:focus-visible {
                    outline: none;
                    border: 1px solid #00acd2;
                }
            }

            .demande-textarea-input {
                background-color: white;
                width: 100%;
                height: 150px;
                textarea {
                    border-radius: 8px;
                    // -webkit-text-fill-color: transparent;
                    z-index: 0;
                }

                .demande-textarea-input__suggestions {
                    li {
                        padding: 5px 10px;

                        &:hover {
                            color: #18b1d4;
                        }
                    }
                }

                .demande-textarea-input__highlighter {
                    padding: 10px;
                    z-index: 1;
                    font-size: 14px;
                    pointer-events: none;

                    strong {
                        color: #18b1d4;
                    }

                    span {
                        visibility: visible !important;
                        color: black;
                    }
                }

                .demande-textarea-input--multiLine .demande-textarea-input__control {
                    font-family: monospace;
                    font-size: 14pt;
                }

                .demande-textarea-input__suggestions__list {
                    background-color: white;
                    border: 1px solid #f0f0f0;
                    border-radius: 8px;
                    font-size: 10pt;
                }

                .demande-textarea-input__suggestions__item {
                    padding: 5px 15px;
                }

                .demande-textarea-input__suggestions__item--focused {
                    background-color: #cee4e5;
                }
            }
        }

        .demande-btn-group {
            @include flexb(row, flex-end, center);
            gap: 15px;
            margin-top: 20px;

            .sob-btn {
                // width: 180px;
                min-width: 180px;
                justify-content: center;
                height: 54px;
            }

            .btn {
                width: 150px;
                justify-content: center;
                height: 54px;
            }

            .basic-select {
                max-width: 260px;
            }
        }
    }

    .demande-textArea-container-side {
        background-color: transparent;
        padding: 0;
    }
}
