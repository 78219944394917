@mixin flexb($dir: row, $justify: center, $items: center) {
    display: flex;
    justify-content: $justify;
    align-items: $items;
    flex-direction: $dir;
}

.dashbord-fastAction_Card {
    background-color: white;
    padding: 1rem 1rem;
    border-radius: 16px;
    margin-top: 20px;
    margin-bottom: 20px;

    .sob-spinner-loading {
        height: 60px;
        h3 {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

    .dashbord-fastAction_Card-header {
        @include flexb(row, flex-start);
        gap: 10px;
    }

    .h6 {
        font-weight: 300;
        font-size: 14px;
        margin: 0;
    }

    .dashbord-fastAction_Card-content {
        @include flexb(row, space-between);
        // margin-top: 20px;

        .dashbord-highlight {
            font-size: 35px;
            font-weight: 700;
            color: #3e79e8;

            // &[label="warning"] {
            //     color: #d6b71e;
            // }
        }

        a {
            text-decoration: none;
        }

        .dashbord-fastAction_Card-btn {
            @include flexb;
            gap: 10px;
            margin: 0;
            background-color: #3e79e815;
            color: #3e79e8;
            font-size: 12px;
            font-weight: 600;
            height: 53px;
            width: 90px;
            outline: none;
            border: none;
            border-radius: 16px;
            cursor: pointer;
            transition: all 0.2s ease-out;

            img {
                width: 15px;
            }

            &:hover {
                filter: brightness(0.7);
            }
        }
    }
}
