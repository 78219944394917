.offer-creation-card {
    .sob-isMulti {
        .basic-select__control {
            min-height: 54px;
        }
    }

    .shipping-palier_global-group {
        border: solid 0.5px #8b8b8b;
        border-radius: 8px;
        padding: 20px 20px;
        margin: 17px -15px 35px;

        .palier-global-container {
            .sob-v2-col {
                flex-basis: content;
                flex-grow: 0;
            }

            .palier-btn-global {
                max-width: 100%;
                max-height: 100%;
                display: block;
                cursor: pointer;
                margin: 12px;

                &:hover {
                    filter: brightness(0.8);
                }
            }

            .palier-global-info {
                background-color: #4caace15;
                min-width: 139px;
                height: 98px;
                color: #4caace;
                font-size: 14px;
                border-radius: 8px;
                padding: 10px;
                margin: 12px 0;
                cursor: pointer;

                .palier-global-info_text {
                    line-height: 1.8;
                    font-weight: 300;
                    span {
                        margin-left: 10px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.product-pack-container {
    .palier-btn {
        --sob-v2-color-palette-primary: #5cb4e5;
        --sob-v2-color-palette-primary-hover: #56aad9;
    }

    .sob-v2-row:first-child {
        margin: 0 30px;
    }

    .sob-v2-col {
        min-width: 12%;
        margin-bottom: 15px;
    }

    .productPack-name {
        min-width: 35%;
    }

    .pack-palier-btn {
        min-width: 10%;
        max-width: 12%;
    }

    .product-pack {
        width: 100%;
        border-radius: 11px;
        border: 2px solid #00acd2;
        padding: 20px;
        margin-bottom: 20px;

        .sob-v2-row {
            margin-left: 0;
            margin-right: 0;
            font-size: 13px;

            .sob-v2-col {
                @include flexb(row, flex-start);
            }
        }

        .pack-actions {
            position: relative;

            & > div {
                margin-top: 10px;
            }
            .sob-v2-switch-buttoncontainer {
                margin-top: 0;
            }

            .pack-delete-btn {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                cursor: pointer;
                max-width: 35px;
            }
        }
    }
}
