.planning-event-tables {
    @include flexb(row, center, flex-start);
    gap: 20px;

    .labs-table {
        padding: 20px;

        .planning-event-big_title {
            font-size: 22px;
            font-weight: 600;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #efefef;
        }

        .planning-event-table_head {
            @include flexb(row, flex-start);

            .table-tag {
                width: fit-content;
                padding: 0 8px;
                font-size: 19px;
                font-weight: 500;
                margin-left: 10px;
            }
        }

        .sob-empty-data {
            min-height: 300px;
        }
    }

    .table-tag {
        width: 80px;
    }

    .planning-event-icon {
        display: block;
        max-width: 40px;
        cursor: pointer;
    }

    .planning-event-inputs {
        @include flexb(row, space-between, center);
        // gap: 20px;
        // padding-bottom: 20px;
        // margin-bottom: 20px;
        border-bottom: 1px solid #efefef;

        .sob-v2-form-group {
            width: 100%;
            margin: 10px;

            @media screen and (max-width: 1320px) {
                margin: 5px;

                .sob-v2-label {
                    font-size: 0.8rem;
                }
            }
        }

        @media screen and (max-width: 1200px) {
            flex-wrap: wrap;
        }
    }
}
