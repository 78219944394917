.inputPopUp-container {
    .sob-modal-content {
        max-width: 700px;
        padding: 20px;
        // max-height: 760px;
        overflow-y: auto;

        .sob-modal-header {
            padding: 0;
        }

        .sob-modal-body {
            .sob-col {
                padding: 8px;
            }

            .sob-form-group {
                margin: 0;
                margin-bottom: 20px;
                position: relative;

                .sob-form-control {
                    height: 54px;
                }

                .sob-invalid-feedback {
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                }
            }
        }

        .sob-modal-footer {
            justify-content: flex-end;
        }
    }
}

.inputPopUp-removeMargin {
    .sob-v2-card {
        max-height: 100% !important;
        margin-top: 0 !important;
    }
}
