.labs-textArea {
    border: 1px solid #c6c6c6;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    resize: none;
    padding: 10px;

    &[aria-invalid='true'] {
        border: 1px solid #f05a29;
    }

    &:focus-visible {
        outline: none;
        border: 1px solid #00acd2;
    }
}
