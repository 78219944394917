.labs {
    // Helpers
    @import './helpers/Variables';
    @import './helpers/Mixins';
    @import './basics/Buttons';

    background-color: #f4f4f4;
    min-height: 100vh;
    padding-bottom: 50px;
    // height: 100%;

    * {
        font-family: 'Poppins';
        box-sizing: border-box;
    }

    .sob-megaNavBar-container .sob-megaNavBar-items .sob-megaNavBar-item > a {
        margin-top: 0;
        padding-top: 13px;
    }
    .sob-megaNavBar-container .sob-megaNavBar-items .sob-megaNavBar-item .sob-megaNavBar-subMenu {
        top: 100%;
    }

    .loader {
        min-height: 100vh;
        @include flexb;
    }

    .Toastify__toast-container {
        z-index: 99999;
    }

    .sob-select__menu {
        z-index: 9999;
        // color: #acd200;
    }
    .labs-container {
        // max-width: 1600px;
        margin: 0 auto;
        padding: 0 24px;

        .labs-header-btns {
            @include flexb;
            gap: 20px;

            .sob-select {
                flex-shrink: 0;
                flex-grow: 1;
                min-width: 200px;
            }
        }

        .sob-card {
            padding: 20px;
        }
    }

    .labs-offers-card {
        padding: 0;
    }

    .sob-v2-table {
        tr {
            height: 50px;

            td {
                vertical-align: middle;

                img {
                    display: block;
                }
            }

            .table-actions-btn {
                cursor: pointer;
            }
        }
    }

    .table-tag {
        // height: 45px;
        // min-height: 45px;
        @include flexb;
        // font-size: 13px;
        // font-weight: 500;
        // padding: 10px;
        width: 100px;
    }

    .tag-wide {
        width: 180px;
    }

    .wrapper-editor {
        border: 1px solid #c6c6c6;
        border-radius: 16px;
        min-height: 250px;
        padding: 1rem;

        .editor-container {
            min-height: 200px;
            cursor: text;
        }
    }

    .labs-detail-container {
        // max-width: 1600px;
        margin: 0 auto;
        padding: 0 24px 0;

        .labs-detail-header {
            @include flexb(row, space-between);
            min-height: 70px;

            .labs-detail-btns {
                @include flexb;
                gap: 20px;

                .sob-select {
                    min-width: 200px;
                }

                .sob-btn {
                    height: 45px;
                    margin: 0;
                }
            }

            a {
                text-decoration: none;
            }
        }

        .labs-detail-body {
            @include flexb(row, center, flex-start);
            gap: 20px;

            .tabPanel {
                border-radius: 16px;
                border-top-left-radius: 0;

                .MuiBox-root {
                    border-radius: 16px;
                }
            }

            .labs-detail-cards {
                flex-basis: 70%;
                flex-grow: 1;

                .sob-card {
                    margin: 0;
                    // padding: 3%;
                    padding: 20px;

                    .sob-card-item-title {
                        span {
                            font-weight: 400;
                        }
                    }
                }
            }

            .sob-audit-card {
                margin: 0;
            }

            .labs-detail-sidebar {
                min-width: 300px;
                max-width: 400px;
                flex-grow: 1;

                .sob-audit-card {
                    margin: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }

    #flag {
        min-width: 5.3rem;
        width: fit-content;
        padding: 0 5px;
    }

    .mapping-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .combined-fields {
        display: flex;

        & > div:first-child {
            width: 70%;
        }
    }

    .sob-v2-vtab-container {
        margin: 0;
    }

    //****components
    @import '../components/offers/OffersListHeader';
    @import '../components/offers/OfferType';
    //****pages
    @import './pages/AccessDenied';
    @import './pages/Nav';
    @import './pages/dashboard/TableauDeBord';
    @import './pages/products/Products';
    @import './pages/products/SuggestProduct';
    @import './pages/calender/calenderCreation';
    @import './pages/planning/PlanningEvent';
    @import './pages/publication/Publication';
    @import './pages/rapports/Rapports';
    @import './pages/galerie/Galerie';
    @import './pages/settings/Settings';
    @import './pages/Signin';
    @import './pages/Failed';
    @import './pages/demandes/Demandes';
    @import './pages/Ventes/Offers';
    @import './pages/Ventes/OffersTable';
    @import './pages/Ventes/OfferCreation';
    @import './pages/Ventes/OfferDetails';
    @import './pages/Ventes/orders/OrderDetails';
    @import './pages/Ventes/orders/OrderCreation';

    // Components

    //     @import "../components/offers/OffersListHeader";
    //     @import "../components/offers/Steps";
}
