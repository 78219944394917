.publication-email-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    html {
        font-size: 16px;
    }

    .container-table {
        padding: 35px 25px;
    }

    .logo {
        width: 150px;
    }

    .container-table-cards {
        padding: 40px;
    }

    .innerTable-col {
        padding: 18px;
    }

    .table-card {
        border-spacing: 7px;
    }

    @media (max-width: 1300px) {
        .container-table {
            padding: 20px 10px;
        }

        .container-table-cards {
            padding: 30px;
        }

        .innerTable-col {
            padding: 8px;
        }

        .table-card {
            border-spacing: 5px;
        }
    }

    @media (max-width: 1200px) {
        html {
            font-size: 13px;
        }

        .container-table {
            padding: 10px 5px;
        }

        .logo {
            width: 120px;
        }
        .container-table-cards {
            padding: 10px;
        }

        .innerTable-col {
            padding: 4px;
        }

        .table-card {
            border-spacing: 2px;
            padding: 0 !important;
        }
    }

    @media (max-width: 350px) {
        html {
            font-size: 12px;
        }
    }
}
