.rapports-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 20px;
    align-items: start;

    .accord-links {
        height: fit-content;
        border-radius: 13px;
        border: 1px solid #eaeaea;
        margin-bottom: 20px;

        &[aria-selected='true'] {
            border: 1px solid #00acd2;
        }

        &[aria-disabled='true'] {
            pointer-events: none;
            .accordion__heading {
                .accordion__button {
                    background-color: rgb(238, 238, 238);

                    img {
                        filter: brightness(0.9);
                        opacity: 0.7;
                    }
                }
            }
        }

        .accordion__heading {
            .accordion__button {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 82px;
                background-color: white;
                border: 1px solid #eaeaea;
                border-radius: 13px;
                box-shadow: 0 0 10px 5px rgba(211, 211, 211, 0.151);

                &::before {
                    display: none;
                }

                .accord-number {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    height: 100%;
                    width: 62px;
                    border-right: 1px solid #eaeaea;
                    font-size: 20px;
                    font-weight: 600;
                    color: black;
                }

                .accord-title {
                    margin-right: auto;
                    font-size: 16px;
                    font-weight: 500;
                    margin-left: 70px;
                    color: black;
                }
            }
        }

        .accordion__panel {
            padding: 0;
            height: fit-content;

            ul {
                margin: 0;
                padding: 0;
                height: fit-content;
                display: flex;
                flex-direction: column;

                a {
                    padding: 20px;
                    text-decoration: none;
                    font-size: 14px;

                    &:visited {
                        color: black;
                    }

                    &:active {
                        color: black;
                    }

                    &:focus {
                        color: black;
                    }

                    color: black;

                    &:hover {
                        color: #00acd2;
                        background-color: #00acd211;
                    }
                }
            }
        }
    }

    // .AccordionCard_big_container {
    //     height: 100%;
    // }

    // .rapport-link {
    //     margin: 10px 20px;
    //     text-decoration: none;
    // }
}

.rapport-table-body {
    td {
        font-weight: 600;

        &:first-child {
            font-weight: 400;
        }
    }
}
