.pubCreation-container {
    @include flexb(row, center, flex-start);
    gap: 20px;

    .pubCreation-cards {
        width: 100%;

        .pub-card-img-container {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .pub-showCase {
        width: 65%;

        h5 {
            margin: 20px 0;
        }

        .Card_container {
            width: 100%;
        }
    }
}
