.sob-drag-row {
    display: flex;
    flex-direction: row;
    margin-left: -15px;
    margin-right: -15px;
}

.sob-droppable-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    margin-bottom: 8px;
    margin: -9px -9px 0 -9px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 8px;
}
.sob-droppable-title {
    margin: 0 8px;
    font-weight: 600;
    font-size: 0.938rem;
}
.sob-draggable-item-text {
    display: inline;
    margin: 0 8px;
    font-size: 0.875rem;
}
.sob-droppable-container {
    padding: 8px;
    background-color: #fff;
    border: 1.5px solid #efefef;
    border-radius: 8px;
    min-height: 300px;
}

.sob-droppable-items {
    display: flex;
    flex-direction: column;
    min-height: 300px;
}

.dragable-disabled {
    pointer-events: none;
    background-color: lightgray !important;
}
