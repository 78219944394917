@mixin flexb($dir: row, $justify: center, $items: center) {
    display: flex;
    justify-content: $justify;
    align-items: $items;
    flex-direction: $dir;
}

.event-card-container {
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 16px;
    padding: 20px 15px;
    margin: 10px;
    @include flexb(row, flex-start);
    gap: 10px;
    cursor: pointer;

    .event-time {
        @include flexb(column);
        max-height: 52px;

        em {
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            height: 20px;
            @include flexb;
        }
    }

    .event-break {
        width: 3px;
        height: 52px;
        border-radius: 5px;
        background-color: #55aced;
        margin-left: 10px;
    }

    .event-desc {
        margin-left: 10px;

        .event-type {
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 10px;
        }

        .event-title {
            font-size: 13px;
            font-weight: 500;
        }
    }
}
