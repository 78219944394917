body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* :root {
    --sob-v2-color-palette-primary: #c82333 !important;
    --sob-v2-color-palette-primary-hover: #9f4042 !important;
    --sob-v2-color-palette-secondary: #6c757d !important;
    --sob-v2-color-palette-secondary-hover: #5c6267 !important;
} */
