.sob-v2-megaNavBar-container {
    // --sob-primary: #d11c33;
    // --sob-secondary: #d11c3409;
    // --sob-v2-color-palette-primary: #d11c33;
    // --sob-v2-color-palette-secondary: #d11c3409;

    .notification-container {
        @include flexb;
        min-width: 75px;
        width: 75px;
        height: 100%;
        // background-color: #fff6f8;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            background-color: #fdedf1;
        }

        .notif-number {
            background-color: #f22a50;
            border-radius: 50px;
            width: 32px;
            height: 18px;
            margin-top: -30px;
            @include flexb;
            color: white;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .sob-megaNavBar-user_menu {
        min-width: 220px;
    }
}

.labs-navbar {
    background-color: white;
    box-shadow: 0 3px 13px 0 rgb(0 0 0 / 8%);
    height: 75px;

    .navbar {
        // max-width: 1600px;
        margin: 0 auto;
        padding: 0 3%;
        box-shadow: none;
        z-index: 0;
        height: 75px;

        .notification-container {
            @include flexb;
            min-width: 75px;
            width: 75px;
            height: 100%;
            // background-color: #fff6f8;
            cursor: pointer;
            text-decoration: none;

            &:hover {
                background-color: #fdedf1;
            }

            .notif-number {
                background-color: #f22a50;
                border-radius: 50px;
                width: 32px;
                height: 18px;
                margin-top: -30px;
                @include flexb;
                color: white;
                font-size: 12px;
                font-weight: 600;
            }

            img {
                margin-right: -15px;
            }
        }

        .NavSubMenu__link {
            text-decoration: none;
            // background-color: transparent;
        }
    }
}
