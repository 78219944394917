.galerie-container-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    row-gap: 20px;
    column-gap: 10px;
    justify-content: center;
    justify-items: center;

    .galerie-photo-card {
        position: relative;

        .galerie-pic {
            max-width: 270px;
            max-height: 250px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
        }

        .pic-delete-btn {
            position: absolute;
            bottom: 10px;
            right: 10px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
