.clickable-tag {
    cursor: pointer;
}

.order-table {
    .table-responsive {
        overflow: visible;
    }

    table {
        // border-collapse: collapse;
        border-spacing: 10px 10px;
    }

    tbody {
        border: none;
        // overflow: auto;
        // border-top: 1px solid transparent;

        tr,
        td,
        th {
            border-radius: 11px;
            z-index: 0;
            background-color: transparent;
        }

        &::before {
            content: '';
            display: block;
            height: 10px;
        }
    }

    .orders-table-pack {
        // border: 1px #00acd2 solid !important;
        // border-radius: 11px;
        box-shadow: 0 0 0 1px #00acd2;
        border-radius: 11px;
        z-index: 1;
    }
}

.offers-container-side {
    .offre-stopwatch {
        margin-bottom: 20px;
        .sob-v2-card-content {
            padding: 15px 0 0;
            @include flexb(row, space-between, flex-start);
            gap: 5px;

            .offer-time-card {
                h1 {
                    font-size: 35px;
                    font-weight: 600;
                    text-align: center;
                    color: #f01414;
                    margin: 0;
                }

                p {
                    font-size: 13px;
                    color: #f01414;
                    text-align: center;
                }
            }

            strong {
                margin-top: -5px;
                // margin-top: 20px;
                font-size: 35px;
                font-weight: 600;
                color: #f01414;
            }
        }
    }

    .offer-statistics {
        // @include flexb;

        .offer-stats-card {
            margin-bottom: 20px;

            .sob-audit-card-header {
                width: fit-content;
                margin-right: 20px;
            }

            .sob-audit-card-body {
                padding: 0;
            }

            h1 {
                font-size: 25px;
                font-weight: 600;
                margin: 0;

                &[label='views'] {
                    color: #eaa207;
                }

                &[label='clicks'] {
                    color: #fc851f;
                }

                &[label='commands'] {
                    color: #5fc95c;
                }
            }

            p {
                font-size: 13px;
                margin: 0;
            }
        }
    }
}
